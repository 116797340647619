import { Node, mergeAttributes } from '@tiptap/core'

//tiptap node that inserts fontawesome icons into the editor as an <i> tag with the fontawesome class i.e. <i class="fas fa-info-circle"></i>
const CustomNode = Node.create({
    name: 'icons',
    group: 'inline',
    inline: true,
    draggable: true,
    addAttributes() {
        return {
            class: {
                default: null,
                /*parseHTML: element => element.getAttribute('data-icon'),
                // … and customize the HTML rendering.
                renderHTML: attributes => ({
                        'data-icon': attributes.class,
                        class: attributes.class,
                }),*/
            },
        }
    },  
    //parse the icon from the html
    parseHTML() {
        return [
            {
                tag: 'i',
                priority: 100,
                //getAttrs: node => node.getAttribute('data-icon') && null,
            },
        ]
    },
    //render the icon in the editor using the class attribute
    renderHTML({ HTMLAttributes }) {
        return ['i', mergeAttributes(HTMLAttributes, { class: this.options.class })]
    },


    addCommands() {
        return {
            //setIcon takes a font awesome class and inserts it into the editor
            setIcon: (icon) => ({ commands }) => commands.insertContent({ type: this.name, attrs: { class: `${icon} mx-25` } }) //inserts the icon into the editor
            
        }
    },
})
export default CustomNode
