// eslint-disable-next-line import/prefer-default-export
export const listDistricts = /* GraphQL */ `
    query ListDistricts(
        $id: ID
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDistricts(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                schools {
                    items {
                        id
                        name {
                            legal
                            popular
                        }
                        state {
                            enabled
                        }
                    }
                }
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;
export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;
export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                parts
                instruments {
                    nextToken
                }
                hasParts
                hasAlternates
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const createUser = /* GraphQL */ `
    mutation CreateUser(
        $input: CreateUserInput!
        $condition: ModelUserConditionInput
    ) {
        createUser(input: $input, condition: $condition) {
            id
            username
            name {
                first
                last
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            schools {
                items {
                    id
                    schoolID
                    userID
                    createdAt
                    updatedAt
                }
                nextToken
            }
            ensembleIds
            instrumentIds
            zoneIds
            avatar {
                size
                name
                src
            }
            createdAt
            updatedAt
            districtUsersId
        }
    }
`;
export const createUserSchools = /* GraphQL */ `
    mutation CreateUserSchools(
        $input: CreateUserSchoolsInput!
        $condition: ModelUserSchoolsConditionInput
    ) {
        createUserSchools(input: $input, condition: $condition) {
            id
            schoolID
            userID
        }
    }
`;

export const deleteUser = /* GraphQL */ `
    mutation DeleteUser(
        $input: DeleteUserInput!
        $condition: ModelUserConditionInput
    ) {
        deleteUser(input: $input, condition: $condition) {
            id
            username
        }
    }
`;


export const onUpdateDistrict = /* GraphQL */ `
    subscription OnUpdateDistrict {
        onUpdateDistrict {
            id
            name {
                legal
                popular
            }
            zone {
                id
                name
                counties
            }
            schools {
                items {
                    id
                    name {
                        legal
                        popular
                    }
                }
            }
            state {
                enabled
            }
        }
    }
`;
export const onUpdateSchool = /* GraphQL */ `
    subscription OnUpdateSchool {
        onUpdateSchool {
            id
            name {
                legal
                popular
            }
            zone {
                id
                name
                counties
            }
            state {
                enabled
            }
        }
    }
`;
export const onUpdateEnsemble = /* GraphQL */ `
    subscription OnUpdateEnsemble {
        onUpdateEnsemble {
            id
            slug
            name
            parts
            instruments {
                items {
                    id
                    ensembleID
                    instrumentID
                    createdAt
                    updatedAt
                }
                nextToken
            }
            hasParts
            hasAlternates
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;
