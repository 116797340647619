<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Support" :to="{ name: 'support' }"/>
      <b-breadcrumb-item text="Documentation" :to="{ name: 'support-docs' }" />
      <b-breadcrumb-item :text="category ? category.title : slug" active />
    </template>

    <template #actions="{state}">
      <b-button v-if="state.editing" size="sm" variant="outline-link" @click="state.editing = false">
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">Editing</span>
      </b-button>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="support-docs">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </can>
    </template>

    <template #content="{ state }">
      <b-alert :show="enabled === false" variant="danger">
        This system is currently disabled, and only visible to those with the administrator privileges.
      </b-alert>

      <b-row>
        <b-col id="category-tabs-col" cols="12" md="3">
          <h5 class="text-base mb-1 d-flex justify-content-between align-items-center">
            Categories
            <!-- Categories Action -->
            <template v-if="state.editing">
              <can do="create" on="support-docs">
                <b-button v-b-modal="'document-category-modal'" size="sm" variant="link" title="Create Category">
                  <font-awesome-icon icon="fas fa-plus" />
                </b-button>
                <document-category-modal id="document-category-modal"
                                         :categories="categories.items"
                                         @created="addCategory"/>
              </can>
            </template>
          </h5>
          <b-tabs id="category-tabs" v-model="activeTab" vertical pills nav-wrapper-class="w-100" active-nav-item-class="pr-75">
            <template v-if="categories.items.length">
              <b-tab v-for="(category, index) in categories.items" :key="category.id" @click="index !== activeTab ? changeCategory(category) : null">
                <template #title>
                  <div class="d-flex justify-content-between w-100">
                    {{ category.title }}
                  </div>
                  <!-- Active Category Actions -->
                  <template v-if="index === activeTab && state.editing">
                    <can do="manage" on="support-category">
                      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" dropleft
                                  toggle-class="p-0 text-center" :popper-opts="{ positionFixed: false }">
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-white mr-0"/>
                        </template>
                        <b-dropdown-group>
                          <b-dropdown-item @click="toggle()">
                            <font-awesome-icon :icon="`fa-solid fa-${state.editing ? 'file-lines' : 'pen-to-square'}`"></font-awesome-icon>
                            <span class="align-middle ml-50">{{ state.editing ? 'View' : 'Edit' }} Category</span>
                          </b-dropdown-item>
                          <b-dropdown-divider/>
                          <b-dropdown-item @click="$refs.layout.confirmDelete(null, deleteCategory, {
                              confirm: {
                                shouldParse: true,
                                text: `Deleting the <b>${category.title}</b> category will also delete <b>${category.documents.items.length}</b> document${category.documents.items.length > 1 ? 's' : ''} associated to it.`
                              }})">
                            <font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon>
                            <span class="align-middle ml-50">Delete Category</span>
                          </b-dropdown-item>
                        </b-dropdown-group>
                      </b-dropdown>
                    </can>
                  </template>
                </template>
              </b-tab>
            </template>
            <template v-else>
              <b-tab disabled title="No Categories"></b-tab>
            </template>
          </b-tabs>
        </b-col>

        <b-col cols="12" md="9">
          <template v-if="state.editing">
            <b-card>
              <b-row>
                <b-col>
                  <b-form-group label="Title">
                    <b-input v-model="category.title"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Icon">
                    <v-select v-model="category.icon"
                              :options="options.icons" label="icon"
                              :reduce="option => `${option.prefix} fa-${option.icon}`">>
                      <template #option="{ prefix, icon }">
                        <font-awesome-icon :icon="`${prefix} fa-${icon}`" class="mr-1" /> {{ icon }}
                      </template>
                      <template #selected-option="{ prefix, icon }">
                        <font-awesome-icon :icon="`${prefix} fa-${icon}`" class="mr-1" /> {{ icon }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Description">
                    <b-input v-model="category.description"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button variant="primary" class="mt-1 mr-1" @click="updateCategory">Update</b-button>
                  <b-button variant="secondary" class="mt-1" @click="state.editing = false">Cancel</b-button>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-else>
            <b-card v-if="category" no-body>
              <b-card-header header-class="px-1">
                <div class="d-flex justify-content-between w-100 align-items-start">
                  <div class="d-flex align-items-center">
                    <b-avatar rounded class="mr-1" size="md" variant="light-primary">
                      <font-awesome-icon :icon="category.icon" size="lg"></font-awesome-icon>
                    </b-avatar>
                    <div>
                      <h5 class="mb-0">{{ category.title }}</h5>
                      <small v-if="category.description">{{ category.description }}</small>
                    </div>
                  </div>
                  <div>
                    <can do="create" on="support-docs">
                      <b-button v-b-modal="'document-content-modal'" size="sm" variant="link" title="Create Document" class="px-25">
                        <font-awesome-icon icon="fas fa-plus" />
                      </b-button>
                      <document-content-modal id="document-content-modal"
                                              :current-category="category"
                                              :categories="categories.items"
                                              @created="addDocument"/>
                    </can>
<!--                    <can do="manage" on="support-category">
                      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" dropleft
                                  toggle-class="p-0" :popper-opts="{ positionFixed: false }">
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <b-dropdown-item @click="toggle()">
                          <font-awesome-icon :icon="`fa-solid fa-${state.editing ? 'file-lines' : 'pen-to-square'}`"></font-awesome-icon>
                          <span class="align-middle ml-50">Edit Category</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </can>-->
                  </div>
                </div>
              </b-card-header>
              <b-overlay :show="!category.documents" :opacity="1" variant="white">
                <b-list-group flush>
                  <b-list-group-item v-for="document in categoryDocuments"
                                     :key="document.id"
                                     :to="{ name: 'support-docs-document', params: { category: category.slug, slug: document.slug, documentId: document.id }}"
                                     class="d-flex justify-content-between pr-75">
                    <div>
                      <div>{{ document.title }} <span v-if="!document.isPublished" class="ml-50 text-danger font-small-2">(Unpublished)</span></div>
                      <small>{{ document.description }}</small>
                    </div>
                    <!-- Management Options -->
                    <template v-if="$can('manage', 'support-docs')">
                      <b-dropdown id="page-layout-options" v-b-tooltip="'Options'" variant="link" size="sm" no-caret toggle-class="p-25" right>
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                        </template>
                        <can do="update" on="support-docs">
                          <b-dropdown-item @click="updateDocument(document)">
                            <font-awesome-icon :icon="`fa-solid fa-${document.isPublished ? 'eye-slash' : 'eye'}`"></font-awesome-icon>
                            <span class="align-middle ml-50">{{ document.isPublished ? 'Unpublish' : 'Publish' }} Document</span>
                          </b-dropdown-item>
                        </can>
                        <can do="delete" on="support-docs">
                          <b-dropdown-divider/>
                          <b-dropdown-item @click="$refs.layout.confirmDelete({category, document}, deleteDocument, { confirm: { shouldParse: true, text: `Delete the <b>${document.title}</b> document?` } })">
                            <font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon>
                            <span class="align-middle ml-50">Delete Document</span>
                          </b-dropdown-item>
                        </can>
                      </b-dropdown>
                    </template>
                  </b-list-group-item>
                  <b-list-group-item v-if="!categoryDocuments.length" disabled>
                    No documents have been published yet. Check back soon!
                  </b-list-group-item>
                </b-list-group>
              </b-overlay>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </template>

    <template #debug>
      <debug>
        {{ categories }}
      </debug>
    </template>
  </page-layout>

</template>
<script>
import notify from '@/mixins/notify.mixin';
import {uuid} from 'vue-uuid';
import slugify from 'slugify';
import {API, graphqlOperation} from 'aws-amplify';
import {createDocument, createDocumentCategory} from '@/views/support/queries/landing';
import {
  listDocumentCategories,
  listDocumentsByCategory,
  listDocumentCategoryBySlug,
  updateDocumentCategory, deleteDocumentCategory
} from './queries/document-category';
import { fas } from '@fortawesome/free-solid-svg-icons'
import vSelect from 'vue-select';
import PageLayout from '@/components/PageLayout.vue';
import DocumentCategoryModal from '@/views/support/docs/DocumentCategoryModal.vue';
import DocumentContentModal from '@/views/support/docs/DocumentContentModal.vue';
import {deleteDocument, updateDocument} from '@/views/support/docs/queries/document-content';

export default {
  name: 'SupportDocumentCategory',
  components: {
    DocumentContentModal,
    DocumentCategoryModal,
    PageLayout,
    vSelect
  },
  mixins: [ notify ],
  props: {
    id: {
      type: String,
      default: null,
      description: 'category id'
    },
    slug: {
      type: String,
      required: true,
      description: 'category slug'
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      category: null,
      categories: {
        items: [],
        loading: false
      },
      documents: {
        items: [],
        loading: false
      },
      create: {
        category: {
          id: null,
          slug: null,
          icon: null,
          title: null,
          description: null,
        },
      },
      activeTab: null,
      options: {
        icons: [...new Map(Object.values(fas).map(value => ({ prefix: value.prefix, icon: value.iconName})).map(item => [item.icon, item])).values()]
        //icons: Object.values(fas).map(value => ({ prefix: value.prefix, icon: value.iconName}))
      },
      state: {
        editing: false
      }
    }
  },
  computed: {
    isLastTab() {
      return this.activeTab === this.categories?.items?.length || false
    },
    categoryDocuments() {
      if(this.category) {
        if(this.$can('manage', 'support-docs')) {
          return this.category.documents.items
        }
        return this.category.documents.items.filter(doc => doc.isPublished)
      }
     return []
    }
  },
  watch: {
    '$route.params.id'(n, o) {
      const category = this.categories.items.find(item => item.id === n)
      if(category?.documents?.loaded === false) {
        this.listDocumentsByCategory(category)
        this.category = category
        this.activeTab = category.index
      }
    }
  },
  async mounted() {
    await this.listDocumentCategories()
    const category = this.categories.items.find(item => item.slug === this.slug)
    if(category?.documents?.loaded === false) {
      await this.listDocumentsByCategory(category)
      this.category = category
      this.activeTab = category.index
    }
    this.$refs.layout.state.loading = false
  },
  methods: {
    toggle() {
      this.$refs.layout.state.editing = !this.$refs.layout.state.editing
    },
    addCategory(category) {
      this.categories.items.push(category)
    },
    addDocument(document) {
      const documentCategory = this.categories.items.find(item => item.id === document.categoryID)
      if(documentCategory) {
        documentCategory.documents.items.push(document)
      }
    },

    async updateCategory() {
      try {
        const input = {
          id: this.category.id,
          slug: slugify(this.category.title, { lower: true }),
          title: this.category.title,
          description: this.category.description,
          icon: this.category.icon
        }
        const response = await API.graphql(graphqlOperation(updateDocumentCategory, { input: input }));
        const category = response.data.updateDocumentCategory
        await this.$emit('updated', category)

        if(this.category.slug !== input.slug) {
          this.category.slug = input.slug
          await this.$router.push({name: 'support-docs-category', params: { slug: input.slug }})
        }
        this.$refs.layout.state.editing = false
        this.notify({ title: 'Success', text: 'Category was successfully updated', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to update Category', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    async deleteCategory() {
      try {
        const input = { id: this.category.id }
        const response = await API.graphql(graphqlOperation(deleteDocumentCategory, { input: input }));
        const category = response.data.deleteDocumentCategory

        this.categories.items = this.categories.items.filter(item => item.id !== category.id)
        this.changeCategory(this.categories.items[0])
        await this.$emit('deleted', category)
        this.notify({ title: 'Success', text: 'Category was successfully updated', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to update Category', icon: 'fas fa-server', variant: 'danger' });
      }
    },

    async listDocumentCategories(nextToken, pagedItems) {
      this.categories.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentCategories, { limit: 500, nextToken: nextToken }));

      items.push(...response.data.listDocumentCategories.items)

      if(response.data.listDocumentCategories.nextToken) {
        await this.listDocumentCategories(response.data.listDocumentCategories.nextToken, items)
      }
      else {
        this.categories.items = items.sort((a, b) => a.title.localeCompare(b.title)).map((category, index) => ({...category, index: index, documents: { items: [], loading: false, loaded: false } }))
        this.categories.loading = false
      }
    },

    async listDocumentsByCategory(category, nextToken, pagedItems) {
      category.documents.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentsByCategory, { categoryID: category.id, limit: 500, nextToken: nextToken }));

      items.push(...response.data.listDocumentsByCategory.items)

      if(response.data.listDocumentsByCategory.nextToken) {
        await this.listDocumentsByCategory(response.data.listDocumentsByCategory.nextToken, items)
      }
      else {
        category.documents.items = items.sort((a, b) => a.title.localeCompare(b.title))
        category.documents.loading = false
        category.documents.loaded = true
      }
    },
    async updateDocument(document) {
      try {
        document.isPublished = !document.isPublished
        const input = { id: document.id, isPublished: document.isPublished }
        await API.graphql(graphqlOperation(updateDocument, { input: input }));
        this.notify({ title: 'Success', text: 'Document was successfully updated', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to update Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    async deleteDocument({ category, document }) {
      try {
        const input = { id: document.id }
        await API.graphql(graphqlOperation(deleteDocument, { input: input }));
        category.documents.items = category.documents.items.filter(item => item.id !== document.id)
        this.notify({ title: 'Success', text: 'Document was successfully deleted', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to delete Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },

    changeCategory(category) {
      this.category = category

      this.$refs.layout.state.creating = false
      this.$refs.layout.state.editing = false
      this.$router.push({ params: { id: category.id, slug: category.slug}})
    },
    toggleCreate() {
      this.$refs.layout.state.creating = true
      this.$router.push({ params: { id: '1', slug: 'create'}})
      this.create.category = {
        id: null,
        slug: null,
        icon: null,
        title: null,
        description: null,
      }
    }
  }
}
</script>

<style>
#category-tabs .nav-link {
  justify-content: start;
}

@media print {
  #category-tabs-col {
    display: none;
  }
}
</style>
