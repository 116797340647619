<template>
  <b-modal :id="id" ref="modal" centered size="xl" title="Create Category" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-row>
        <b-col>
          <b-form-group label="Title">
            <b-input v-model="category.title"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Icon">
            <v-select v-model="category.icon"
                      :options="options.icons" label="icon"
                      :reduce="option => `${option.prefix} fa-${option.icon}`">>
              <template #option="{ prefix, icon }">
                <font-awesome-icon :icon="`${prefix} fa-${icon}`" class="mr-1" /> {{ icon }}
              </template>
              <template #selected-option="{ prefix, icon }">
                <font-awesome-icon :icon="`${prefix} fa-${icon}`" class="mr-1" /> {{ icon }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Description">
            <b-input v-model="category.description"/>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center" class="text-right pr-0">
          <b-button type="reset" class="mr-1">Clear</b-button>
          <b-button type="submit" variant="primary" @click="createCategory">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import notify from '@/mixins/notify.mixin'
import Avatar from '@/models/avatar';
import {
  listDistricts, listSchools, listEnsembles, listZones,
  createUser, createUserSchools, deleteUser,
  onUpdateDistrict, onUpdateSchool, onUpdateEnsemble
} from '@/graphql/queries/user-modal';
import { API, graphqlOperation } from 'aws-amplify';
import {adminCreateUser, adminAddUserToGroup} from '@/scripts/aws';
import Fuse from 'fuse.js';
import {rules} from '@/data/validation-rules';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import { uuid } from 'vue-uuid';
import {fas} from '@fortawesome/free-solid-svg-icons';
import slugify from 'slugify';
import {createDocumentCategory} from '@/views/support/queries/landing';

export default {
  name: 'DocumentCategoryModal',
  directives: { mask },
  components: {
    vSelect
  },
  mixins: [ notify ],
  props: {
    id: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      category: {
        id: null,
        slug: null,
        icon: null,
        title: null,
        description: null,
      },
      options: {
        icons: [...new Map(Object.values(fas).map(value => ({ prefix: value.prefix, icon: value.iconName})).map(item => [item.icon, item])).values()]
      },
    }
  },
  methods: {
    async createCategory() {
      this.category.id = uuid.v4()
      this.category.slug = slugify(this.category.title, { lower: true })

      try {
        const response = await API.graphql(graphqlOperation(createDocumentCategory, { input: this.category }));
        const category = response.data.createDocumentCategory
        category.documents = { items: [], loading: false, loaded: false }

        await this.$emit('created', category)
        this.$root.$emit('bv::toggle::modal', this.id)
        this.notify({ title: 'Success', text: 'Category was successfully created', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to create Category', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    clear() {
      this.category = {
        id: null,
        slug: null,
        icon: null,
        title: null,
        description: null,
      }
    }
  }
}
</script>

<style scoped>
  #card-container > :last-child {
    margin-bottom: 0;
  }
</style>
