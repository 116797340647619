<template>
  <b-modal :id="id" ref="modal" centered size="xl" title="Create Document" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-row>
        <b-col>
          <b-form-group label="Title">
            <b-input v-model="document.title"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Category">
            <v-select v-model="document.categoryID"
                      :options="options.categories" label="title"
                      :reduce="option => option.id"
                      :disabled="currentCategory != null"/>
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group label="Short Description">
            <b-input v-model="document.description"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="" class="">
            <tip-tap v-model="document.content" />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center" class="pl-0">
          <b-checkbox v-model="document.isPublished" :checked="true" :unchecked-value="false">
            <small>Publish Document</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right pr-0">
          <b-button type="reset" class="mr-1" @click="clear">Clear</b-button>
          <b-button type="submit" variant="primary" @click="createDocument">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import notify from '@/mixins/notify.mixin'
import Avatar from '@/models/avatar';
import {
  listDistricts, listSchools, listEnsembles, listZones,
  createUser, createUserSchools, deleteUser,
  onUpdateDistrict, onUpdateSchool, onUpdateEnsemble
} from '@/graphql/queries/user-modal';
import { API, graphqlOperation } from 'aws-amplify';
import {adminCreateUser, adminAddUserToGroup} from '@/scripts/aws';
import Fuse from 'fuse.js';
import {rules} from '@/data/validation-rules';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import { uuid } from 'vue-uuid';
import {fas} from '@fortawesome/free-solid-svg-icons';
import slugify from 'slugify';
import {createDocumentCategory} from '@/views/support/queries/landing';
import {createDocument} from '@/views/support/docs/queries/document-content';
import TipTap from '@/components/TipTap.vue';

export default {
  name: 'DocumentContentModal',
  directives: { mask },
  components: {
    vSelect,
    TipTap
  },
  mixins: [ notify ],
  props: {
    id: {
      type: String,
      required: true,
    },
    currentCategory: {
      type: Object,
      default: null
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      document: {
        id: null,
        slug: null,
        categoryID: this.currentCategory ? this.currentCategory.id : null,
        title: null,
        description: null,
        content: null,
        isPublished: true,
      },
      options: {
        categories: this.categories
      },
      state: {
        redirect: false
      }
    }
  },
  watch: {
    currentCategory() {
      this.document.categoryID = this.currentCategory?.id
    }
  },
  methods: {
    async createDocument() {
      this.document.id = uuid.v4()
      this.document.slug = slugify(this.document.title, { lower: true })

      try {
        const response = await API.graphql(graphqlOperation(createDocument, { input: this.document }));
        const document = response.data.createDocument

        await this.$emit('created', document)
        this.$root.$emit('bv::toggle::modal', this.id)
        this.notify({ title: 'Success', text: 'Document was successfully created', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to create Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    async createCategory() {
      this.category.id = uuid.v4()
      this.category.slug = slugify(this.category.title, { lower: true })

      try {
        const response = await API.graphql(graphqlOperation(createDocumentCategory, { input: this.category }));
        const category = response.data.createDocumentCategory
        category.documents = { items: [], loading: false, loaded: false }

        await this.$emit('created', category)
        this.$root.$emit('bv::toggle::modal', this.id)
        this.notify({ title: 'Success', text: 'Category was successfully created', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to create Category', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    clear() {
      this.category = {
        id: null,
        slug: null,
        icon: null,
        title: null,
        description: null,
      }
    }
  }
}
</script>

<style scoped>
  #card-container > :last-child {
    margin-bottom: 0;
  }
</style>
