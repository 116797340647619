export const listDocumentCategories = /* GraphQL */ `
    query ListDocumentCategories(
        $id: ID
        $filter: ModelDocumentCategoryFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDocumentCategories(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                title
                description
                icon
            }
            nextToken
        }
    }
`;
export const listDocumentsByCategory = /* GraphQL */ `
    query ListDocumentsByCategory(
        $categoryID: ID!
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDocumentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listDocumentsByCategory(
            categoryID: $categoryID
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                categoryID
                title
                description
                isPublished
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listDocumentCategoryBySlug = /* GraphQL */ `
    query ListDocumentCategoryBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelDocumentCategoryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listDocumentCategoryBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                title
                description
                icon
                documents {
                    items {
                        id
                        slug
                        title
                        description
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const updateDocumentCategory = /* GraphQL */ `
    mutation UpdateDocumentCategory(
        $input: UpdateDocumentCategoryInput!
        $condition: ModelDocumentCategoryConditionInput
    ) {
        updateDocumentCategory(input: $input, condition: $condition) {
            id
            slug
            title
            description
            icon
            documents {
                items {
                    id
                    slug
                    categoryID
                    title
                    description
                    content
                    isPublished
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteDocumentCategory = /* GraphQL */ `
    mutation DeleteDocumentCategory(
        $input: DeleteDocumentCategoryInput!
        $condition: ModelDocumentCategoryConditionInput
    ) {
        deleteDocumentCategory(input: $input, condition: $condition) {
            id
            slug
            title
            description
            icon
            documents {
                items {
                    id
                    slug
                    categoryID
                    title
                    description
                    content
                    isPublished
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
