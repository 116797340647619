<template>
  <div class="d-flex flex-column">
    <!-- Toolbar -->
    <div v-if="editor && config && showToolbar" :class="['tiptap-toolbar-wrapper', toolbarWrapperClass]">
      <!-- Primary -->
      <b-button-toolbar :class="['tiptap-toolbar', 'rounded-top', toolbarPrimaryClass]">
        <!-- Markings -->
        <b-button-group class="pr-1">
          <b-button v-b-tooltip title="Bold"
                    size="sm" variant="link"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }"
                    @click="editor.chain().focus().toggleBold().run()">
            <font-awesome-icon icon="fa-solid fa-bold" />
          </b-button>
          <b-button v-b-tooltip title="Italic"
                    size="sm" variant="link"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }"
                    @click="editor.chain().focus().toggleItalic().run()">
            <font-awesome-icon icon="fa-solid fa-italic" />
          </b-button>
          <b-button v-b-tooltip title="Underline"
                    size="sm" variant="link"
                    :disabled="!editor.can().chain().focus().toggleUnderline().run()"
                    :class="{ 'is-active': editor.isActive('underline') }"
                    @click="editor.chain().focus().toggleUnderline().run()">
            <font-awesome-icon icon="fa-solid fa-underline" />
          </b-button>
          <b-button v-b-tooltip title="Strike"
                    size="sm" variant="link"
                    :disabled="!editor.can().chain().focus().toggleStrike().run()"
                    :class="{ 'is-active': editor.isActive('strike') }"
                    @click="editor.chain().focus().toggleStrike().run()">
            <font-awesome-icon icon="fa-solid fa-strikethrough" />
          </b-button>
        </b-button-group>

        <!-- Font & Color -->
        <template v-if="config.toolbar.primary.fonts || config.toolbar.primary.colors">
					<b-button-group v-if="config.toolbar.primary.fonts"
													class="pr-1 align-items-center">
						<b-dropdown v-b-tooltip title="Font" no-caret size="sm" variant="link" toggle-class="text-decoration-none">
							<template #button-content>
								<font-awesome-icon icon="fa-solid fa-font" />
							</template>
							<b-dropdown-item-button v-for="font in config.toolbar.fonts" :key="font.value" size="sm" variant="link"
																			:class="{ 'is-active': editor.isActive('textStyle', { fontFamily: font.family }) }"
																			@click="editor.chain().focus().setFontFamily(font.family).run()">
								<span class="">{{ font.label }}</span>
							</b-dropdown-item-button>
							<b-dropdown-divider/>
							<b-dropdown-item-button size="sm" variant="link" @click="editor.chain().focus().unsetFontFamily().run()">
								Unset Font
							</b-dropdown-item-button>
						</b-dropdown>
						<b-input type="color"
										 class="border-0 bg-transparent shadow-none"
										 :value="editor.getAttributes('textStyle').color"
										 @change="editor.chain().focus().setColor($event).run()"
						/>
					</b-button-group>
        </template>


        <!-- Headings & Paragraph -->
        <template v-if="config.toolbar.primary.headings || config.toolbar.primary.paragraph">
					<b-button-group class="pr-1">
						<b-dropdown v-if="config.toolbar.primary.headings"
												v-b-tooltip title="Headings" no-caret size="sm" variant="link" toggle-class="text-decoration-none">
							<template #button-content>
								<font-awesome-icon icon="fa-solid fa-heading" />
							</template>
							<b-dropdown-item-button size="sm" variant="link" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
								<h1>Heading 1</h1>
							</b-dropdown-item-button>
							<b-dropdown-item-button size="sm" variant="link" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
								<h2>Heading 2</h2>
							</b-dropdown-item-button>
							<b-dropdown-item-button size="sm" variant="link" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
								<h3>Heading 3</h3>
							</b-dropdown-item-button>
							<b-dropdown-item-button size="sm" variant="link" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()">
								<h4>Heading 4</h4>
							</b-dropdown-item-button>
							<b-dropdown-item-button size="sm" variant="link" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()">
								<h5>Heading 5</h5>
							</b-dropdown-item-button>
							<b-dropdown-item-button size="sm" variant="link" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()">
								<h6>Heading 6</h6>
							</b-dropdown-item-button>
						</b-dropdown>
						<b-button v-if="config.toolbar.primary.paragraph"
											v-b-tooltip title="Paragraph"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive('paragraph') }"
											@click="editor.chain().focus().setParagraph().run()">
							<font-awesome-icon icon="fa-solid fa-paragraph" />
						</b-button>
					</b-button-group>
        </template>

        <!-- Alignment -->
        <template v-if="config.toolbar.primary.alignment">
					<b-button-group  class="pr-1">
						<b-button v-b-tooltip title="Align Left"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
											@click="editor.chain().focus().setTextAlign('left').run()">
							<font-awesome-icon icon="fa-solid fa-align-left" />
						</b-button>
						<b-button v-b-tooltip title="Align Center"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
											@click="editor.chain().focus().setTextAlign('center').run()">
							<font-awesome-icon icon="fa-solid fa-align-center" />
						</b-button>
						<b-button v-b-tooltip title="Align Right"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
											@click="editor.chain().focus().setTextAlign('right').run()">
							<font-awesome-icon icon="fa-solid fa-align-right" />
						</b-button>
						<b-button v-b-tooltip title="Justify"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
											@click="editor.chain().focus().setTextAlign('justify').run()">
							<font-awesome-icon icon="fa-solid fa-align-justify" />
						</b-button>
						<!--          <b-button v-b-tooltip title="Unset Alignment"
                                size="sm" variant="link"
                                @click="editor.chain().focus().unsetTextAlign().run()">
                        <font-awesome-icon icon="fa-solid fa-outdent" />
                      </b-button>-->
					</b-button-group>
        </template>

        <!-- Lists -->
        <template v-if="config.toolbar.primary.list">
					<b-button-group  class="pr-1">
						<b-button v-b-tooltip title="Bullet List"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive('bulletList') }"
											@click="editor.chain().focus().toggleBulletList().run()">
							<font-awesome-icon icon="fa-solid fa-list-ul" />
						</b-button>
						<b-button v-b-tooltip title="Ordered List"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive('orderedList') }"
											@click="editor.chain().focus().toggleOrderedList().run()">
							<font-awesome-icon icon="fa-solid fa-list-ol" />
						</b-button>
					</b-button-group>
        </template>

        <!-- Special -->
        <template v-if="config.toolbar.primary.code
                  || config.toolbar.primary.link
                  || config.toolbar.primary.image
                  || config.toolbar.primary.table
                  || config.toolbar.primary.icons">
					<b-button-group class="pr-1">
						<b-button v-if="config.toolbar.primary.code"
											v-b-tooltip title="Code"
											size="sm" variant="link"
											:disabled="!editor.can().chain().focus().toggleCode().run()"
											:class="{ 'is-active': editor.isActive('code') }"
											@click="editor.chain().focus().toggleCode().run()">
							<font-awesome-icon icon="fa-solid fa-terminal" />
						</b-button>
						<b-button v-if="config.toolbar.primary.link"
											v-b-tooltip title="Link"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive('link') }" @click="setLink">
							<font-awesome-icon icon="fa-solid fa-link" />
						</b-button>
						<b-button v-if="config.toolbar.primary.image"
											v-b-tooltip title="Image"
											size="sm" variant="link"
											@click="modal.visible = true">
							<font-awesome-icon icon="fa-solid fa-image" />
						</b-button>
						<b-button v-if="config.toolbar.primary.table"
											v-b-tooltip title="Table"
											size="sm" variant="link"
											@click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
							<font-awesome-icon icon="fa-solid fa-table" />
						</b-button>
						<b-dropdown v-if="config.toolbar.primary.icons"
												v-b-tooltip title="Icon" no-caret size="sm" variant="link" toggle-class="text-decoration-none">
							<template #button-content>
								<font-awesome-icon icon="fa-solid fa-icons" />
							</template>
							<b-dropdown-item-button v-for="icon in config.toolbar.icons" :key="icon.value" size="sm" variant="link"
																			@click="editor.chain().focus().setIcon(icon.class).run()">
								<font-awesome-icon :icon="icon.class" class="mr-1" />
								<span class="font-small-3">{{ icon.label }}</span>
							</b-dropdown-item-button>
							<!--<b-dropdown-divider/>
              <b-dropdown-item-button size="sm" variant="link" @click="editor.chain().focus().unsetFontFamily().run()">
                Unset Font
              </b-dropdown-item-button>-->
						</b-dropdown>
					</b-button-group>
        </template>

        <!-- Blocks -->
        <template v-if="config.toolbar.primary.codeBlock || config.toolbar.primary.blockQuote">
					<b-button-group class="pr-1">
						<b-button v-if="config.toolbar.primary.codeBlock"
											v-b-tooltip title="Code Block"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive('codeBlock') }"
											@click="editor.chain().focus().toggleCodeBlock().run()">
							<font-awesome-icon icon="fa-solid fa-code" />
						</b-button>
						<b-button v-if="config.toolbar.primary.blockQuote"
											v-b-tooltip title="Block Quote"
											size="sm" variant="link"
											:class="{ 'is-active': editor.isActive('blockquote') }"
											@click="editor.chain().focus().toggleBlockquote().run()">
							<font-awesome-icon icon="fa-solid fa-quote-left" />
						</b-button>
					</b-button-group>
        </template>


        <!-- Misc -->
        <b-dropdown v-if="config.toolbar.primary.more"
                    v-b-tooltip title="More"
                    no-caret size="sm" variant="link"
                    toggle-class="text-decoration-none" class="pr-1">
          <template #button-content>
            <font-awesome-icon icon="fa-solid fa-ellipsis" />
          </template>
          <b-button v-b-tooltip title="Horizontal Rule"
                    size="sm" variant="link"
                    @click="editor.chain().focus().setHorizontalRule().run()">
            hr
          </b-button>
          <b-button v-b-tooltip title="Line Break"
                    size="sm" variant="link"
                    @click="editor.chain().focus().setHardBreak().run()">
            br
          </b-button>
        </b-dropdown>

        <!-- Clear -->
        <b-dropdown v-if="config.toolbar.primary.clear"
                    v-b-tooltip title="Clear"
                    no-caret size="sm" variant="link"
                    toggle-class="text-decoration-none" class="pr-1">
          <template #button-content>
            <font-awesome-icon icon="fa-solid fa-eraser" />
          </template>
          <b-dropdown-item-button size="sm" variant="link" @click="editor.chain().focus().unsetAllMarks().run()">
            clear marks
          </b-dropdown-item-button>
          <b-dropdown-item-button size="sm" variant="link" @click="editor.chain().focus().clearNodes().run()">
            clear nodes
          </b-dropdown-item-button>
        </b-dropdown>

        <!-- Undo/Redo -->
        <b-button-group>
          <b-button v-b-tooltip title="Undo"
                    size="sm" variant="link"
                    :disabled="!editor.can().chain().focus().undo().run()"
                    @click="editor.chain().focus().undo().run()">
            <font-awesome-icon icon="fa-solid fa-rotate-left" />
          </b-button>
          <b-button v-b-tooltip title="Redo"
                    size="sm" variant="link"
                    :disabled="!editor.can().chain().focus().redo().run()"
                    @click="editor.chain().focus().redo().run()">
            <font-awesome-icon icon="fa-solid fa-rotate-right" />
          </b-button>
        </b-button-group>

        <!-- Options -->
        <b-dropdown v-if="config.toolbar.primary.options"
                    v-b-tooltip title="Options"
                    no-caret lazy
                    size="sm" variant="link"
                    menu-class="dropdown-menu-xl"
                    toggle-class="text-decoration-none"
                    :class="['ml-auto', toolbarPrimaryOptionsClass]"
                    dropright>
          <template #button-content>
            <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
          </template>

          <b-dropdown-group header="Primary Toolbar" header-classes="font-small-3" class="">
            <b-dropdown-form>
              <div v-for="(value, prop) in config.toolbar.primary" :key="prop" class="d-flex justify-content-between align-items-center">
                <span class="align-middle font-small-3 text-capitalize">{{ prop }}</span>
                <b-button :pressed.sync="config.toolbar.primary[prop]" variant="link" size="sm" class="px-0 border-0">
                  <font-awesome-icon :icon="`fa-solid fa-toggle-${config.toolbar.primary[prop] ? 'on' : 'off'}`" />
                </b-button>
              </div>
            </b-dropdown-form>
          </b-dropdown-group>
          <b-dropdown-divider/>
          <b-dropdown-group header="Other Toolbars" header-classes="font-small-3">
            <b-dropdown-form>
              <div class="d-flex justify-content-between align-items-center">
                <span class="align-middle font-small-3">Table Bubble</span>
                <b-button :pressed.sync="config.toolbar.table.bubble.show" variant="link" size="sm" class="px-0 border-0">
                  <font-awesome-icon :icon="`fa-solid fa-toggle-${config.toolbar.table.bubble.show ? 'on' : 'off'}`" />
                </b-button>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="align-middle font-small-3">Word Count</span>
                <b-button :pressed.sync="config.toolbar.counter.show" variant="link" size="sm" class="px-0 border-0">
                  <font-awesome-icon :icon="`fa-solid fa-toggle-${config.toolbar.counter.show ? 'on' : 'off'}`" />
                </b-button>
              </div>
            </b-dropdown-form>
          </b-dropdown-group>

          <can do="dev">
            <b-dropdown-divider/>
            <b-dropdown-group header="Developer" header-classes="font-small-3">
              <b-dropdown-form>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="align-middle font-small-3">HTML View</span>
                  <b-button :pressed.sync="config.dev.html" variant="link" size="sm" class="px-0 border-0">
                    <font-awesome-icon :icon="`fa-solid fa-toggle-${config.dev.html ? 'on' : 'off'}`" />
                  </b-button>
                </div>
              </b-dropdown-form>
            </b-dropdown-group>
          </can>
        </b-dropdown>


      </b-button-toolbar>

      <!-- Table -->
      <b-button-toolbar v-if="editor.isActive('table')" class="tiptap-toolbar border-top">
        <!-- Columns -->
        <b-button-group>
          <b-button v-if="editor.can().addColumnBefore()"
                    v-b-tooltip title="Add Column Before"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addColumnBefore().run()">
            <font-awesome-icon icon="fa-solid fa-table-columns"/>
            <span class="align-middle ml-25">Add Column Before</span>
          </b-button>

          <b-button v-if="editor.can().addColumnAfter()"
                    v-b-tooltip title="Add Column After"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addColumnAfter().run()">
            <font-awesome-icon icon="fa-solid fa-table-columns" />
            <span class="align-middle ml-25">Add Column After</span>
          </b-button>

          <b-button v-if="editor.can().deleteColumn()"
                    v-b-tooltip title="Delete Column"
                    size="sm" variant="link"
                    @click="editor.chain().focus().deleteColumn().run()">
            <font-awesome-icon icon="fa-solid fa-table-columns" />
            <span class="align-middle ml-25">Delete Column</span>
          </b-button>
        </b-button-group>

        <!-- Rows -->
        <b-button-group>
          <b-button v-if="editor.can().addRowBefore()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addRowBefore().run()">
            <font-awesome-icon icon="fa-solid fa-table-list" />
            <span class="align-middle ml-25">Add Row Before</span>
          </b-button>
          <b-button v-if="editor.can().addRowAfter()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addRowAfter().run()">
            <font-awesome-icon icon="fa-solid fa-table-list" />
            <span class="align-middle ml-25">Add Row After</span>
          </b-button>
          <b-button v-if="editor.can().deleteRow()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().deleteRow().run()">
            <font-awesome-icon icon="fa-solid fa-table-list" />
            <span class="align-middle ml-25">Delete Row</span>
          </b-button>
        </b-button-group>

        <!-- Toggles -->
        <!--        <b-button-group>
                  <b-button v-if="editor.can().toggleHeaderColumn()"
                            v-b-tooltip title="Paragraph"
                            size="sm" variant="link"
                            @click="editor.chain().focus().toggleHeaderColumn().run()">
                    <font-awesome-icon icon="fa-solid fa-table-cells-large" /> Toggle Header Column
                  </b-button>
                  <b-button v-if="editor.can().toggleHeaderRow()"
                            v-b-tooltip title="Paragraph"
                            size="sm" variant="link"
                            @click="editor.chain().focus().toggleHeaderRow().run()">
                    <font-awesome-icon icon="fa-solid fa-table-cells-large" /> Toggle Header Row
                  </b-button>
                  <b-button v-if="editor.can().toggleHeaderCell()"
                            v-b-tooltip title="Paragraph"
                            size="sm" variant="link"
                            @click="editor.chain().focus().toggleHeaderCell().run()">
                    <font-awesome-icon icon="fa-solid fa-table-cells-large" /> Toggle Header Cell
                  </b-button>
                </b-button-group>-->

        <!-- Merge/Split -->
        <b-button-group>
          <b-button v-b-tooltip title="Merge or Split"
                    size="sm" variant="link"
                    :disabled="!editor.can().mergeOrSplit()"
                    @click="editor.chain().focus().mergeOrSplit().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells" />
            <span class="align-middle ml-25">Merge / Split</span>
          </b-button>
        </b-button-group>

        <b-button v-b-tooltip title="Delete Table"
                  size="sm" variant="link"
                  :disabled="!editor.can().deleteTable()"
                  @click="editor.chain().focus().deleteTable().run()">
          <font-awesome-icon icon="fa-solid fa-table" />
          <span class="align-middle ml-25">Delete Table</span>
        </b-button>
      </b-button-toolbar>

      <!--Counter -->
      <b-button-toolbar v-if="config.toolbar.counter.show && editor" class="tiptap-toolbar border-top font-small-3">
        <b-button size="sm" variant="link" disabled>
          <font-awesome-icon icon="fa-solid fa-align-left" />
          <span class="align-middle ml-25">{{ editor.storage.characterCount.characters() }} Characters</span>
        </b-button>
        <b-button size="sm" variant="link" disabled>
          <font-awesome-icon icon="fa-solid fa-align-left" />
          <span class="align-middle ml-25">{{ editor.storage.characterCount.words() }} Words</span>
        </b-button>
      </b-button-toolbar>
    </div>

    <!-- Table Bubble -->
    <bubble-menu v-if="editor && config && showToolbar && config.toolbar.table.bubble.show"
                 :editor="editor"
                 :tippy-options="{ duration: 100, maxWidth: 'none' }"
                 :should-show="() => editor.isActive('table')">

      <b-button-toolbar>
        <!-- Columns -->
        <b-button-group>
          <b-button v-if="editor.can().addColumnBefore()"
                    v-b-tooltip title="Add Column Before"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addColumnBefore().run()">
            <font-awesome-icon icon="fa-solid fa-table-columns"/> Add Column Before
          </b-button>

          <b-button v-if="editor.can().addColumnAfter()"
                    v-b-tooltip title="Add Column After"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addColumnAfter().run()">
            <font-awesome-icon icon="fa-solid fa-table-columns" /> Add Column After
          </b-button>

          <b-button v-if="editor.can().deleteColumn()"
                    v-b-tooltip title="Delete Column"
                    size="sm" variant="link"
                    @click="editor.chain().focus().deleteColumn().run()">
            <font-awesome-icon icon="fa-solid fa-table-columns" /> Delete Column
          </b-button>
        </b-button-group>

        <!-- Rows -->
        <b-button-group>
          <b-button v-if="editor.can().addRowBefore()"
                    v-b-tooltip title="Add Row Before"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addRowBefore().run()">
            <font-awesome-icon icon="fa-solid fa-table-list" /> Add Row Before
          </b-button>
          <b-button v-if="editor.can().addRowAfter()"
                    v-b-tooltip title="Add Row After"
                    size="sm" variant="link"
                    @click="editor.chain().focus().addRowAfter().run()">
            <font-awesome-icon icon="fa-solid fa-table-list" /> Add Row After
          </b-button>
          <b-button v-if="editor.can().deleteRow()"
                    v-b-tooltip title="Delete Row"
                    size="sm" variant="link"
                    @click="editor.chain().focus().deleteRow().run()">
            <font-awesome-icon icon="fa-solid fa-table-list" /> Delete Row
          </b-button>
        </b-button-group>

        <!-- Toggles -->
<!--        <b-button-group>
          <b-button v-if="editor.can().toggleHeaderColumn()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().toggleHeaderColumn().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells-large" /> Toggle Header Column
          </b-button>
          <b-button v-if="editor.can().toggleHeaderRow()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().toggleHeaderRow().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells-large" /> Toggle Header Row
          </b-button>
          <b-button v-if="editor.can().toggleHeaderCell()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().toggleHeaderCell().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells-large" /> Toggle Header Cell
          </b-button>
        </b-button-group>-->

        <!-- Merge/Split -->
        <b-button-group v-if="editor.can().mergeOrSplit()">
<!--          <b-button v-if="editor.can().mergeCells()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().mergeCells().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells" /> Merge Cells
          </b-button>
          <b-button v-if="editor.can().splitCell()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().splitCell().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells" /> Split Cells
          </b-button>-->

          <b-button v-if="editor.can().mergeOrSplit()"
                    v-b-tooltip title="Paragraph"
                    size="sm" variant="link"
                    @click="editor.chain().focus().mergeOrSplit().run()">
            <font-awesome-icon icon="fa-solid fa-table-cells" /> mergeOrSplit
          </b-button>
        </b-button-group>

        <b-button v-if="editor.can().deleteTable()"
                  v-b-tooltip title="Paragraph"
                  size="sm" variant="link"
                  @click="editor.chain().focus().deleteTable().run()">
          <font-awesome-icon icon="fa-solid fa-table" /> Delete Table
        </b-button>
      </b-button-toolbar>






    </bubble-menu>

    <!-- Editor -->
    <editor-content v-if="editor" :editor="editor" />

    <b-modal id="image-modal" v-model="modal.visible" title="Image" centered @hidden="modal.file = null">
      <b-form-file v-model="modal.file"
                   accept="image/*"
                   placeholder="Choose a file or drop it here..."
                   drop-placeholder="Drop file here..." />

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" :disabled="modal.file === null" @click="addImage(ok)">OK</b-button>
        <b-button variant="secondary" @click="cancel">Cancel</b-button>
      </template>
    </b-modal>

    <!-- HTML -->
    <debug v-if="editor && config && config.dev.html" title="HTML View" code-language="html">
      {{ editor.getHTML() }}
    </debug>

  </div>
</template>

<script>

import { Editor, BubbleMenu, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import ExtensionColor from '@tiptap/extension-color'
import ExtensionFontFamily from '@tiptap/extension-font-family'
import ExtensionTextStyle from '@tiptap/extension-text-style'
import ExtensionLink from '@tiptap/extension-link'
import ExtensionUnderline from '@tiptap/extension-underline'
import ExtensionTextAlign from '@tiptap/extension-text-align'
import ExtensionImage from '@tiptap/extension-image'
import ExtensionCharacterCount from '@tiptap/extension-character-count'
import ExtensionTable from '@tiptap/extension-table'
import ExtensionTableHeader from '@tiptap/extension-table-header'
import ExtensionTableRow from '@tiptap/extension-table-row'
import ExtensionTableCell from '@tiptap/extension-table-cell'
import ExtensionPlaceholder from '@tiptap/extension-placeholder'
import ExtentionIcon from '@/components/extention-icons'
import {fas} from '@fortawesome/free-solid-svg-icons';

export default {
  components: {
    EditorContent,
    BubbleMenu
  },
  props: {
    showToolbar: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null
    },
    toolbarOptions: {
      type: Object,
      default: null
    },
    extensionLink: {
      type: Object,
      default: () => ({
        openOnClick: true,
        openOnFocus: false,
        autolink: true,
        linkOnPaste: true,
      }),
    },
    classes: {
      type: String,
      default: null
    },
    toolbarWrapperClass: {
      type: String,
      default: null
    },
    toolbarPrimaryClass: {
      type: String,
      default: null
    },
    toolbarPrimaryOptionsClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      modal: {
        visible: false,
        file: null
      },
      editor: null,
      config: {
        toolbar: {
          primary: {
            paragraph: true,
            headings: true,
            fonts: true,
            alignment: true,
            list: true,
            code: false,
            link: true,
            image: true,
            table: true,
            codeBlock: false,
            blockQuote: false,
            clear: false,
            more: false,
            schools: false,
            icons: true,
            options: true,
          },
          fonts: [
            { family: 'Arial', label: 'Arial' },
            { family: 'Georgia', label: 'Georgia' },
            { family: 'Times New Roman', label: 'Times New Roman' },
            { family: 'Courier New', label: 'Courier New' },
            { family: 'Montserrat', label: 'Montserrat' },
            { family: 'Helvetica', label: 'Helvetica' },
            { family: 'serif', label: 'Serif' },
            { family: 'Cedarville Cursive', label: 'Cedarville Cursive' },
            { family: 'Freehand', label: 'Freehand' },
            { family: 'Kristi', label: 'Kristi' },
            { family: 'La Belle Aurore', label: 'La Belle Aurore' },
            { family: 'Pinyon Script', label: 'Pinyon Script' },
            { family: 'Sacramento', label: 'Sacramento' },
            { family: 'Satisfy', label: 'Satisfy' },
          ],
          icons: [

            { label: 'Gear', class: 'fas fa-gear'},
            { label: 'Ellipsis', class: 'fas fa-ellipsis-vertical'},
            { label: 'View', class: 'fas fa-file-lines'},
            { label: 'Edit', class: 'fas fa-pen-to-square'},
            { label: 'Clone', class: 'fas fa-copy'},
            { label: 'Delete', class: 'fas fa-trash'},
            { label: 'Save', class: 'fas fa-cloud-arrow-up'},
            { label: 'Refresh', class: 'fas fa-rotate-right'},
            { label: 'Export', class: 'fas fa-cloud-arrow-down'},
            { label: 'Print', class: 'fas fa-print'},
            { label: 'Help', class: 'fas fa-circle-question'},
            { label: 'Plus', class: 'fas fa-plus'},
            { label: 'Dash', class: 'fas fa-minus'},
            { label: 'Filter', class: 'fas fa-filter'},
            { label: 'Sort', class: 'fas fa-sort'},

            { label: 'Music', class: 'fas fa-music'},

            { label: 'Star', class: 'fas fa-star'},
            { label: 'School', class: 'fas fa-school'},
            { label: 'User', class: 'fas fa-user'},

          ],
          //icons: [...new Map(Object.values(fas).map(value => ({ prefix: value.prefix, icon: value.iconName})).map(item => [item.icon, item])).values()]
          table: {
            bubble: {
              show: false
            }
          },
          counter: {
            show: false
          },
          schools: {
            items: [],
          }
        },
        dev: {
          html: false
        }
      }
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) { return }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    if(this.toolbarOptions) {
      Object.entries((this.toolbarOptions)).forEach((entry) => {
        const [key, value] = entry;
        if(this.config.toolbar.primary[key] !== null) {
          this.config.toolbar.primary[key] = value
        }
      })
    }
    this.editor = new Editor({
      content: this.value,
      editorProps: {
        attributes: {
          class: this.classes ? this.classes : null,
        },
      },
      enableInputRules: false,
      enablePasteRules: false,
      extensions: [
        StarterKit,
        ExtensionPlaceholder.configure({
          placeholder: this.placeholder ? this.placeholder : 'Write Something...',
        }),
        ExtensionColor,
        ExtensionTextStyle,
        ExtensionFontFamily,
        ExtensionLink.configure({
          openOnClick: this.extensionLink.openOnClick,
          openOnFocus: this.extensionLink.openOnFocus,
          autolink: this.extensionLink.autolink,
          linkOnPaste: this.extensionLink.linkOnPaste,
          HTMLAttributes: {
            class: 'text-primary',
          },
        }),
        ExtensionUnderline,
        ExtensionTextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        ExtensionImage.configure({
          inline: true,
          allowBase64: true,
        }),
        ExtensionTable.configure({
          resizable: true,
        }),
        ExtensionTableRow,
        ExtensionTableHeader,
        ExtensionTableCell,
        ExtensionCharacterCount,
        ExtentionIcon
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    async addImage(ok) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      this.editor.chain().focus().setImage({ src: await toBase64(this.modal.file), width: 200 }).run()
      ok()
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      if (url === null) {
        return
      }

      if (url === '') {
        this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()
        return
      }

      // update link
      this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
    },
  }
}
</script>

<style>
.tiptap-toolbar-wrapper {
  margin-bottom: 1rem;
}
.tiptap-toolbar-wrapper .btn-toolbar .dropdown-menu .dropdown-item {
  width: 100%;
}
.tiptap-toolbar {
  background-color: #f6f6f6;
}

.tiptap-toolbar:last-child {
  border-bottom-left-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}

.tiptap-toolbar .dropdown-menu {
  min-width: 14rem!important;
}

.tiptap-toolbar input[type="color"] {
  height: 20px;
  width: 2rem;
  padding: 0;
}

.tiptap-toolbar input[type="color" i]::-webkit-color-swatch {
  border-radius: 0.357rem !important;
}



</style>

<style>
  .editor-xs {
    min-height: 50px!important;
  }
  .editor-sm {
    min-height: 100px!important;
  }

  .ProseMirror {
    min-height: 200px;
    outline: unset;
    padding: 0;
    //background-color: #fff;
    background-clip: padding-box;
  }

  /* Placeholder (at the top) */
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  .ProseMirror a {
    text-decoration: underline;
  }

  .ProseMirror img {
    max-width: 100%;
    height: auto;
  }

  .ProseMirror blockquote {
    background: #f9f9f9;
    border-left: 2px solid #ccc;
    margin: .5rem;
    padding: 1rem;
    quotes: "\201C""\201D""\2018""\2019";
  }
  .ProseMirror blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 2rem;
    line-height: 0.1rem;
    margin-right: 0.25rem;
    vertical-align: middle;
  }
  .ProseMirror blockquote p {
    display: inline;
  }

  .ProseMirror li > p {
    margin-bottom: 0;
  }
</style>

<style scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css';
</style>

<style lang="scss">

.tippy-box {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

  button > svg {
    margin-right: 0.25rem;
  }
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1rem;
      height: 1rem;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
