export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;

export const createDocumentCategory = /* GraphQL */ `
    mutation CreateDocumentCategory(
        $input: CreateDocumentCategoryInput!
        $condition: ModelDocumentCategoryConditionInput
    ) {
        createDocumentCategory(input: $input, condition: $condition) {
            id
            slug
            title
            description
            icon
            createdAt
            updatedAt
        }
    }
`;

export const createDocument = /* GraphQL */ `
    mutation CreateDocument(
        $input: CreateDocumentInput!
        $condition: ModelDocumentConditionInput
    ) {
        createDocument(input: $input, condition: $condition) {
            id
            slug
            category {
                id
                slug
                title
                description
                icon
                documents {
                    nextToken
                }
                createdAt
                updatedAt
            }
            categoryID
            title
            description
            content
            isPublished
            createdAt
            updatedAt
        }
    }
`;

export const listDocumentCategories = /* GraphQL */ `
    query ListDocumentCategories(
        $id: ID
        $filter: ModelDocumentCategoryFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDocumentCategories(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                title
                description
                icon
                documents {
                    items {
                        id
                        slug
                        title
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

